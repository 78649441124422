import React, { useEffect } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

// Styles
import GlobalStyles from "./home/components/global"

import waitlist from "../images/nightlife/waitlist-background.png"

import waitlistMobile from "../images/nightlife/waitlist-mobile.png"

function Waitlist() {
  useEffect(() => {
    if (window.analytics) {
      window.analytics.track("Visited Waitlist")
    }
  }, [])

  const clickedButton = () => {
    if (window.analytics) {
      window.analytics.track("Clicked Waitlist Button")
    }
  }

  return (
    <Container>
      <Background />
      <GlobalStyles />
      <InnerContainer>
        <PositioningDiv>
          <TextDiv>
            <SlimHeading>Queue is the new social media...</SlimHeading>

            <ThickHeading>
              made for <Yellow>partying</Yellow> <br />
              and <Yellow>meeting</Yellow> new people near you.
            </ThickHeading>
          </TextDiv>
        </PositioningDiv>
      </InnerContainer>
      <Footer>
        <StyledLink href="https://queue-app.com/about-queue">
          <WhatIsQueue>More about Queue</WhatIsQueue>
        </StyledLink>
      </Footer>
    </Container>
  )
}

export default Waitlist

// <a onClick={() => clickedButton()} href="#" class="vrlps-trigger">
//   <Button>
//     <ButtonText>Get Early Access</ButtonText>
//   </Button>
// </a>

// <Helmet>
//   <script
//     dangerouslySetInnerHTML={{
//       __html: `
//           !function(a,b,c,d,t){var e,f=a.getElementsByTagName("head")[0];if(!a.getElementById(c)){if(e=a.createElement(b),e.id=c,e.setAttribute("data-vrlps-ucid",d),e.setAttribute("data-vrlps-version","2"), e.setAttribute("data-vrlps-template", t),e.src="https://app.viral-loops.com/popup_assets/js/vl_load_v2.min.js",window.ub){jQuery=null,$=null;var g=a.createElement(b);g.src="https://code.jquery.com/jquery-2.2.4.min.js",f.appendChild(g)}f.appendChild(e);var h=a.createElement("link");h.rel="stylesheet",h.type="text/css",h.href="https://app.viral-loops.com/static/vl-loader.css",f.appendChild(h);var i=a.createElement("div");i.id="vl-overlay",i.style.display="none";var j=a.createElement("div");j.id="vl-loader",i.appendChild(j),a.addEventListener("DOMContentLoaded",function(b){a.body.appendChild(i);for(var c=a.getElementsByClassName("vrlps-trigger"),d=0;d<c.length;d++)c[d].removeAttribute("href"),c[d].onclick=function(){a.getElementById("vl-overlay").style.display="block"};var e=a.querySelectorAll("[data-vl-widget='popupTrigger']");[].forEach.call(e,function(b){var c=a.createElement("div");c.className="vl-embedded-cta-loader",b.appendChild(c)})})}}(document,"script","vrlps-js","BGHcMtlELPLQfZazlL2q7YTktQ4","waitlist");
//         `,
//     }}
//   />
// </Helmet>

const Container = styled.div`
  width: 100vw;
  height: 100%;

  // resetting
  margin: 0;
  padding: 0;

  // color
  background-color: #121212;
  position: fixed;

  z-index: 1;

  * {
    margin: 0;
  }
`

const Background = styled.img`
  content: url(${waitlist});
  position: absolute;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  opacity: 0.35;
  z-index: 0;

  margin: 0;

  @media screen and (max-width: 400px) {
    content: url(${waitlistMobile});
    object-fit: cover;
    align-self: flex-start;

    height: 100%;
    width: 100%;
    transform: scale(1);
  }
`

const InnerContainer = styled.div`
  margin: 0 auto;
  padding: 0;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 2;
`

const PositioningDiv = styled.div`
  margin: 0;
  z-index: 2;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
`
const TextDiv = styled.div`
  margin: 0;
  align-self: center;
  width: 70%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`

const Button = styled.div`
  z-index: 3;
  background-color: #fdde68;
  width: 250px;
  height: 55px;
  border-radius: 5px;
  flex: 1;

  @media screen and (max-width: 750px) {
    width: 200px;
    height: 40px;
  }

  @media screen and (max-width: 660px) {
    width: 190px;
    height: 37px;
  }
`

const ButtonText = styled.p`
  justify-content: center;
  text-align: center;
  line-height: 55px;
  align-items: center;
  font-family: Arial Black, Arial Bold, Gadget, sans-serif;
  font-size: 1.2rem;
  color: #333;

  @media screen and (max-width: 750px) {
    font-size: 1.1rem;
    line-height: 40px;
  }

  @media screen and (max-width: 660px) {
    font-family: Arial, Helvetica Neue, Helvetica, sans-serif !important;
    font-weight: 700;
    font-size: 1rem;
    line-height: 37px;
  }
`

const SlimHeading = styled.h1`
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-weight: lighter;
  color: #fdde68;

  margin: 0;
  padding: 0;

  margin-bottom: 30px;

  @media screen and (max-width: 750px) {
    font-size: 1.5rem;
    margin-bottom: 30px;
  }

  @media screen and (max-width: 600px) {
    font-size: 1.4rem;
  }
`
const ThickHeading = styled.h1`
  font-family: Arial Black, Arial Bold, Gadget, sans-serif;
  color: #fff;
  line-height: 2.5rem;
  margin: 0;

  margin-bottom: 50px;

  padding: 0;
  @media screen and (max-width: 750px) {
    font-size: 1.5rem;
    line-height: 1.4rem;
  }

  @media screen and (max-width: 600px) {
    font-size: 1.4rem;
    margin-bottom: 40px;
  }
`

const Yellow = styled.span`
  color: #fdde68;
`

const Footer = styled.div`
  width: 100vw;
  height: 20%;

  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  bottom: 0;
  z-index: 1;
`

const WhatIsQueue = styled.div`
  // resetting
  margin: 0;
  padding: 0;

  // typography
  font-size: 1.3rem;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-weight: lighter;
  color: #fdde68;

  margin: 0;
  padding: 0;

  margin-bottom: 30px;
  margin-top: 40px;

  @media screen and (max-width: 750px) {
    font-size: 1.1rem;
  }
`

const StyledLink = styled(props => <a {...props} />)`
  // resetting
  margin: 0;
  padding: 0;

  // typography
  font-size: 2rem;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-weight: lighter;
  color: #fdde68;

  margin: 0;
  padding: 0;

  margin-bottom: 30px;

  // color
  color: #fdde68;

  // display-options
  display: flex;
  align-items: center;
  justify-content: center;
`
